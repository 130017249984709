<script>

export default {
  data() {
    return {
      submit_loading: false,      
    };
  },
  mounted() {
    
    
  },
  methods: {
    setLoading(value){
      this.submit_loading = value;
    }
  },
};
</script>
