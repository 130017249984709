<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1000px">
      <v-card>
        <v-form v-on:submit.prevent="createItem" ref="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row mt-3">
              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="operacion" label="Operacion*" name="operacion" v-model="item.operacion" v-validate="'required'" :disabled="true" />
                <span class="red--text">{{ errors.first("operacion") }}</span>
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.shipper"
                  data-vv-as="shipper"
                  name="shipper"
                  item-text="name"
                  item-value="id"
                  :items="shippers"
                  label="Shiper"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("shipper") }}</span>
              </div>

              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  :items="consignees"
                  label="Consignee"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>
              </div>

              <div class="col-md-6">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="house_bl" label="House BL*" name="house_bl" v-model="item.house_bl" :disabled="true" />
                <span class="red--text">{{ errors.first("house_bl") }}</span>
              </div>

              <div class="col-md-6">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="master_bl" label="Master BL*" name="master_bl" v-model="item.master_bl" :disabled="true" />
                <span class="red--text">{{ errors.first("master_bl") }}</span>
              </div>

              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Recoleccion"
                  :date="item.fecha_recoleccion"
                  :isRequired="false"
                  name="fecha_recoleccion"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_recoleccion = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Recoleccion (Confirmada)"
                  :date="item.fecha_recoleccion_confirmada"
                  :isRequired="false"
                  name="fecha_recoleccion_confirmada"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_recoleccion_confirmada = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Salida (ETD)*"
                  :date="item.fecha_salida_etd"
                  :isRequired="false"
                  name="fecha_salida_etd"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_salida_etd = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Salida (ATD)"
                  :date="item.fecha_salida_atd"
                  :isRequired="false"
                  name="fecha_salida_atd"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_salida_atd = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Llegada (ETA)"
                  :date="item.fecha_llegada_eta"
                  :isRequired="false"
                  name="fecha_llegada_eta"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_llegada_eta = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Llegada (ATA)"
                  :date="item.fecha_llegada_ata"
                  :isRequired="false"
                  name="fecha_llegada_ata"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_llegada_ata = date;
                    }
                  "
                />
              </div>

              <div class="col-md-12">
                <v-combobox
                  v-model="item.ultimo_estado"
                  data-vv-as="ultimo_estado"
                  name="ultimo_estado"
                  item-text="name"
                  item-value="id"
                  :items="estados"
                  label="Estado"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("ultimo_estado") }}</span>
              </div>
              <div class="col-md-12">
                <v-textarea outlined data-vv-as="comentarios" label="Comentarios*" name="comentarios" v-model="item.comentarios" :disabled="isViewModal()"></v-textarea>
                <span class="red--text">{{ errors.first("comentarios") }}</span>
              </div>

              <div class="col-md-3">
                <v-combobox
                  v-model="item.puerto_salida"
                  data-vv-as="puerto_salida"
                  name="puerto_salida"
                  item-text="name"
                  item-value="id"
                  :items="puertos"
                  label="Puerto de salida"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("puerto_salida") }}</span>
              </div>
              <div class="col-md-3">
                <v-combobox
                  v-model="item.ultimo_buque"
                  data-vv-as="ultimo_buque"
                  name="ultimo_buque"
                  item-text="name"
                  item-value="id"
                  :items="buques"
                  label="Buque"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("ultimo_buque") }}</span>
              </div>

              <div class="col-md-3">
                <v-combobox
                  v-model="item.puerto_llegada"
                  data-vv-as="puerto_llegada"
                  name="puerto_llegada"
                  item-text="name"
                  item-value="id"
                  :items="puertos"
                  label="Puerto de llegada"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("puerto_llegada") }}</span>
              </div>

              <div class="col-md-3">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="referencia_cliente" label="Referencia cliente*" name="referencia_cliente" v-model="item.referencia_cliente" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("referencia_cliente") }}</span>
              </div>

              <div class="col-md-12">
                <!-- READ ONLY -->
                <v-checkbox label="LCL" :disabled="true" v-model="item.lcl"></v-checkbox>
              </div>

              <hr class="mt-4" />
              <div class="col-md-4" v-if="item.lcl">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="bultos" label="Bultos" name="bultos" v-model="item.bultos" v-validate="'integer|min:0'" :disabled="true" />
                <span class="red--text">{{ errors.first("bultos") }}</span>
              </div>

              <div class="col-md-4" v-if="item.lcl">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="kilos" label="Kilos" name="kilos" v-model="item.kilos" v-validate="'integer|min:0'" :disabled="true" />
                <span class="red--text">{{ errors.first("kilos") }}</span>
              </div>

              <div class="col-md-4" v-if="item.lcl">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="m3" label="M3" name="m3" v-model="item.m3" v-validate="'integer|min:0'" :disabled="true" />
                <span class="red--text">{{ errors.first("m3") }}</span>
              </div>

              <div v-if="!item.lcl" class="col-md-12">                
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Contenedores</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field data-vv-as="cantidad de contenedores" label="Cantidad de Conenedores" name="cantidad_conenedores" v-model="item.cantidad_conenedores" v-validate="'integer|min:0'" :disabled="isViewModal()" />
                      <span class="red--text">{{ errors.first("cantidad_conenedores") }}</span>

                      <InputTable
                        :isViewModal="isViewModal()"
                        :validator="$validator"
                        :headers="[{ text: 'ID Contenedor' }, { text: 'Tipo contenedor' }, { text: 'Nº Contenedor' }, { text: 'KGs' }, { text: 'M3' }, { text: 'Acciones' }]"
                        :items="item.contenedores"
                        :input_fields="[
                          { width: '20%', label: 'ID Contenedor', name: 'id_contenedor', type: 'text', required: false },
                          { label: 'Tipo contenedor', name: 'tipo_contenedor', type: 'select', required: false, list: tipos_contenedores, list_id: 'id', list_name: 'name' },
                          { label: 'Nº de contenedor*', name: 'numero', type: 'text', required: false },
                          { label: 'KGs', name: 'kgs', type: 'numeric', required: false, validate: 'decimal:2|min:0' },
                          { label: 'M3', name: 'm3', type: 'numeric', required: false, validate: 'decimal:2|min:0' },
                        ]"
                        :item_template="{
                          id: 0,
                          id_contenedor: '',
                          numero: '',
                          tipo_contenedor: null,
                          kgs: '',
                          33: '',
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Packing List</b>
                    </v-expansion-panel-header>
                    <!-- READ ONLY -->
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="true"
                        table_title="Packing List"
                        :validator="$validator"
                        :headers="[{ text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.packing_lists"
                        :input_fields="[
                          {
                            label: 'Seleccionar packing list',
                            name: 'packing_list_attached',
                            name_attached: 'packing_list_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          packing_list_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b> Facturas</b>
                      <!-- READ ONLY -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="true"
                        table_title="Facturas"
                        :validator="$validator"
                        :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.facturas"
                        :input_fields="[
                          { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false },
                          {
                            label: 'Seleccionar factura',
                            name: 'factura_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          nro_factura: '',
                          factura_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Facturas de Agente</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="isViewModal()"
                        table_title="Facturas de Agente"
                        :validator="$validator"
                        :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.facturas_agentes"
                        :input_fields="[
                          { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false },
                          {
                            label: 'Seleccionar factura',
                            name: 'factura_agente_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          nro_factura: '',
                          factura_agente_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-4">
                <v-combobox
                  v-model="item.naviera"
                  data-vv-as="naviera"
                  name="naviera"
                  item-text="name"
                  item-value="id"
                  :items="navieras"
                  label="Naviera"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("naviera") }}</span>
              </div>

              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Descarga Trasbordo"
                  :date="item.fecha_descarga_trasbordo"
                  :isRequired="false"
                  name="fecha_descarga_trasbordo"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_descarga_trasbordo = date;
                    }
                  "
                />
              </div>

              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Carga Trasbordo"
                  :date="item.fecha_carga_trasbordo"
                  :isRequired="false"
                  name="fecha_carga_trasbordo"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_carga_trasbordo = date;
                    }
                  "
                />
              </div>

              <div class="col-md-12">
                <v-checkbox label="SIM" :disabled="isViewModal()" v-model="item.sim"></v-checkbox>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <v-checkbox label="Pago MANI" :disabled="isViewModal()" v-model="item.pago_mani"></v-checkbox>
                  </div>
                  <div class="col-md-4">
                    <DatePickerEsp
                      label="Fecha Carga Mani"
                      :date="item.fecha_carga_mani"
                      :isRequired="false"
                      name="fecha_carga_mani"
                      :isViewModal="isViewModal()"
                      @setDate="
                        (date) => {
                          item.fecha_carga_mani = date;
                        }
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>MANI</b>
                    </v-expansion-panel-header>
                    <!-- READ ONLY -->
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="isViewModal()"
                        table_title="MANI"
                        :validator="$validator"
                        :headers="[{ text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.manis"
                        :input_fields="[
                          {
                            label: 'Seleccionar factura',
                            name: 'mani_attached',
                            name_attached: 'mani_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          mani_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <v-checkbox label="Proformas Control" :disabled="isViewModal()" v-model="item.proformas_control"></v-checkbox>
                  </div>

                  <div class="col-md-4">
                    <v-text-field data-vv-as="proformas_numero" label="Proformas Nº" name="proformas_numero" v-model="item.proformas_numero" :disabled="isViewModal()" />
                    <span class="red--text">{{ errors.first("proformas_numero") }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col">
                    <v-checkbox label="Courier Control" :disabled="isViewModal()" v-model="item.courier_control"></v-checkbox>
                  </div>
                  <div class="col">
                    <v-text-field data-vv-as="courier_numero" label="Courier Nº" name="courier_numero" v-model="item.courier_numero" :disabled="isViewModal()" />
                    <span class="red--text">{{ errors.first("courier_numero") }}</span>
                  </div>

                  <div class="col" v-if="isViewModal() && item.courier_path != null">
                    Courrier
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="mr-5" medium color="blue" @click="download(item.courier_path, item.id)"> mdi-download </v-icon>
                      </template>
                      <span>Descargar</span>
                    </v-tooltip>
                  </div>
                  <div class="col" v-if="!isViewModal()">
                    <v-file-input truncate-length="15" name="courier_attached" accept="application/pdf" v-model="item.courier_attached" label="Seleccione el Courier"></v-file-input>
                    <span class="red--text">{{ errors.first("courier_attached") }}</span>
                  </div>
                  <div class="col">
                    <DatePickerEsp
                      label="Fecha recepcion Courier"
                      :date="item.fecha_recepcion_courier"
                      :isRequired="false"
                      name="fecha_recepcion_courier"
                      :isViewModal="isViewModal()"
                      @setDate="
                        (date) => {
                          item.fecha_recepcion_courier = date;
                        }
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Envio Doc Oficina Centro"
                  :date="item.fecha_envio_doc_oficina_centro"
                  :isRequired="false"
                  name="fecha_envio_doc_oficina_centro"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_envio_doc_oficina_centro = date;
                    }
                  "
                />
              </div>

              <div class="col-md-4">
                <v-combobox
                  v-model="item.terminal_dep_fiscal"
                  data-vv-as="terminal_dep_fiscal"
                  name="terminal_dep_fiscal"
                  item-text="name"
                  item-value="id"
                  :items="terminales_dep_fiscal"
                  label="Terminal/Dep. Fiscal"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("terminal_dep_fiscal") }}</span>
              </div>

              <div class="col-md-12">
                <v-textarea outlined data-vv-as="observaciones" label="Observaciones" name="observaciones" v-model="item.observaciones" :disabled="isViewModal()"></v-textarea>
                <span class="red--text">{{ errors.first("observaciones") }}</span>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <v-text-field data-vv-as="viaje_arribo_puerto" label="Viaje Arribo Puerto" name="viaje_arribo_puerto" v-model="item.viaje_arribo_puerto" :disabled="isViewModal()" />
                    <span class="red--text">{{ errors.first("viaje_arribo_puerto") }}</span>
                  </div>

                  <div class="col-md-4">
                    <v-combobox
                      v-model="item.codigo_puerto"
                      data-vv-as="codigo_puerto"
                      name="codigo_puerto"
                      item-text="name"
                      item-value="id"
                      :items="codigos_puertos"
                      label="Codigo Puerto"
                      autocomplete="off"
                      :disabled="isViewModal()"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("codigo_puerto") }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Transmision Anticipada"
                  :date="item.fecha_transmision_anticipada"
                  :isRequired="false"
                  name="fecha_transmision_anticipada"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_transmision_anticipada = date;
                    }
                  "
                />
              </div>

              <div class="col-md-4">
                <v-combobox
                  v-model="item.agente"
                  data-vv-as="agente"
                  name="agente"
                  item-text="name"
                  item-value="id"
                  :items="agentes"
                  label="Agente"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("agente") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <!--<v-btn color="warning" text @click="nextModal(item)" v-if="!isViewModal() && this.item.id == 0">Siguiente</v-btn>-->
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import ModalMixin from "@/mixins/ModalMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import InputTable from "./InputTable.vue";

export default {
  mixins: [ModalMixin, RolMixin],
  components: { DatePickerEsp, InputTable },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    print: Function,
  },
  data() {
    return {
      menu: false,
      show: false,
      shippers: [],
      consignees: [],
      estados: [],
      puertos: [],
      buques: [],
      tipos_contenedores: [],
      navieras: [],
      codigos_puertos: [],
      agentes: [],
      terminales_dep_fiscal: [],
    };
  },
  watch: {
    item: {
      handler(val) {
        this.$emit("updateItem", this.item);
      },
      deep: true,
    },

    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    setDateValue(value, name) {
      this.item[name] = value;
    },
    isViewModal() {
      return this.formBtn == "";
    },
    loadComboData() {
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "estados").then((response) => {
        this.estados = response.result;
      });
      generalService.filter({}, "puertos").then((response) => {
        this.puertos = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "buques").then((response) => {
        this.buques = response.result;
      });
      generalService.filter({}, "tipos_contenedores").then((response) => {
        this.tipos_contenedores = response.result;
      });

      generalService.filter({}, "navieras").then((response) => {
        this.navieras = response.result;
      });
      generalService.filter({}, "codigos_puertos").then((response) => {
        this.codigos_puertos = response.result;
      });
      generalService.filter({}, "agentes").then((response) => {
        this.agentes = response.result;
      });
      generalService.filter({}, "terminales_dep_fiscal").then((response) => {
        this.terminales_dep_fiscal = response.result;
      });
    },
    createItem(e, nextFlag = false) {
      return this.$validator.validate().then((valid) => {
        if (valid) {
          if (e != null) e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          this.setLoading(true);

          let data = new FormData();
          data.append("id", this.item.id);
          data.append("operacion", this.item.operacion);
          data.append("shipper_id", this.item.shipper.id);
          data.append("consignee_id", this.item.consignee.id);

          data.append("sim", this.item.sim ? 1 : 0);
          data.append("pago_mani", this.item.pago_mani ? 1 : 0);
          data.append("proformas_control", this.item.proformas_control ? 1 : 0);
          data.append("courier_control", this.item.courier_control ? 1 : 0);

          if (this.item.referencia_cliente != null) data.append("referencia_cliente", this.item.referencia_cliente);

          if (this.item.fecha_salida_atd != null)
            data.append("fecha_salida_atd", this.item.fecha_salida_atd);

          if (this.item.proformas_numero != null) data.append("proformas_numero", this.item.proformas_numero);
          if (this.item.courier_numero != null) data.append("courier_numero", this.item.courier_numero);
          if (this.item.observaciones != null) data.append("observaciones", this.item.observaciones);
          if (this.item.viaje_arribo_puerto != null) data.append("viaje_arribo_puerto", this.item.viaje_arribo_puerto);

          if (this.item.courier_attached != null) data.append("courier_attached", this.item.courier_attached);

          if (this.item.fecha_descarga_trasbordo != null) data.append("fecha_descarga_trasbordo", this.item.fecha_descarga_trasbordo);
          if (this.item.fecha_carga_trasbordo != null) data.append("fecha_carga_trasbordo", this.item.fecha_carga_trasbordo);
          if (this.item.fecha_carga_mani != null) data.append("fecha_carga_mani", this.item.fecha_carga_mani);
          if (this.item.fecha_recepcion_courier != null) data.append("fecha_recepcion_courier", this.item.fecha_recepcion_courier);
          if (this.item.fecha_envio_doc_oficina_centro != null) data.append("fecha_envio_doc_oficina_centro", this.item.fecha_envio_doc_oficina_centro);
          if (this.item.fecha_transmision_anticipada != null) data.append("fecha_transmision_anticipada", this.item.fecha_transmision_anticipada);

          if (this.item.ultimo_buque != null) data.append("ultimo_buque_id", this.item.ultimo_buque.id);
          if (this.item.ultimo_estado != null) data.append("ultimo_estado_id", this.item.ultimo_estado.id);
          if (this.item.puerto_salida != null) data.append("puerto_salida_id", this.item.puerto_salida.id);
          if (this.item.puerto_llegada != null) data.append("puerto_llegada_id", this.item.puerto_llegada.id);
          if (this.item.naviera != null) data.append("naviera_id", this.item.naviera.id);
          if (this.item.agente != null) data.append("agente_id", this.item.agente.id);
          if (this.item.terminal_dep_fiscal != null) data.append("terminal_dep_fiscal_id", this.item.terminal_dep_fiscal.id);
          if (this.item.codigo_puerto != null) data.append("codigo_puerto_id", this.item.codigo_puerto.id);

          if (this.item.fecha_llegada_eta != null) data.append("fecha_llegada_eta", this.item.fecha_llegada_eta);
          if (this.item.fecha_llegada_ata != null) data.append("fecha_llegada_ata", this.item.fecha_llegada_ata);
          if (this.item.comentarios != null) data.append("comentarios", this.item.comentarios);

          if (this.item.cantidad_conenedores != null) data.append("cantidad_conenedores", this.item.cantidad_conenedores);
          data.append("cantidad_conenedores_arr", this.item.contenedores.length);          
          if (this.item.contenedores.length > 0) {
            for (var i = 0; i < this.item.contenedores.length; i++) {
              data.append("contenedores_arr[]", JSON.stringify(this.item.contenedores[i]));
            }
          }

          //no se pueden adjuntar archivos en un array anidado
          data.append("cantidad_facturas_agente", this.item.facturas_agentes.length);
          if (this.item.facturas.length > 0) {
            for (var j = 0; j < this.item.facturas_agentes.length; j++) {
              data.append("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
              data.append("facturas_agentes_" + j + "_nro_factura", this.item.facturas_agentes[j].nro_factura);
              data.append("facturas_agentes_" + j + "_attached", this.item.facturas_agentes[j].factura_agente_attached);
              console.log("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
            }
          }

          //no se pueden adjuntar archivos en un array anidado
          data.append("cantidad_manis", this.item.manis.length);
          if (this.item.manis.length > 0) {
            for (var v = 0; v < this.item.manis.length; v++) {
              data.append("mani_" + v + "_id", this.item.manis[v].id);
              data.append("mani_" + v + "_attached", this.item.manis[v].manis_attached);
            }
          }

          if (this.item.id > 0) {
            return this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => this.setLoading(false));
          } else {
            return this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false, nextFlag);
                  this.$emit("loadList");
                  if (nextFlag) this.$validator.reset();
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
                return response;
              })
              .finally(() => this.setLoading(false));
          }
        }
      });
    },

    printModal() {
      this.createItem(null).then((response) => {
        this.$emit("print", response.result, "pdf");
      });
    },

    nextModal() {
      this.createItem(null, true);
    },
  },
};
</script>
