const SECRET_PHRASE = "AD@keron*di5tribution";

const SECTION_USER = "users";
const SECTION_ROLE = "roles";

const SECTION_CONSIGNEE = "consignees";
const SECTION_SHIPPER = "shippers";
const SECTION_INSSURING_CARRIER = "inssuing_carriers";
const SECTION_AIRPORT = "airports";
const SECTION_CARRIER = "carriers";
const SECTION_CURRENCY = "currencies";

const SECTION_TIPO_CONTENEDOR = "tipos_contenedores";
const SECTION_PUERTO = "puertos";
const SECTION_BUQUE = "buques";
const SECTION_COMPANIA_TRASNPORTE = "companias_transporte";
const SECTION_TIPO_CAMION = "tipos_camiones";
const SECTION_LUGAR = "lugares";

const SECTION_CODIGO_PUERTO = "codigos_puertos";
const SECTION_NAVIERA = "navieras";
const SECTION_TERMINAL_DEP_FISCAP = "terminales_dep_fiscal";
const SECTION_AGENTE = "agentes";
const SECTION_ESTADO = "estados";

const SECTION_ORDERS = "orders";

const SECTION_ORDERS_MARITIMAS_COM = "orders_maritimas_comercial";
const SECTION_ORDERS_MARITIMAS_OP = "orders_maritimas_operaciones";
const SECTION_ORDERS_TERRESTRES_COM = "orders_terrestres_comercial";
const SECTION_ORDERS_TERRESTRES_OP = "orders_terrestres_operaciones";

export default { 
    SECRET_PHRASE: SECRET_PHRASE,
    SECTION_SHIPPER: SECTION_SHIPPER,
    SECTION_CONSIGNEE: SECTION_CONSIGNEE,
    SECTION_INSSURING_CARRIER: SECTION_INSSURING_CARRIER,
    SECTION_AIRPORT: SECTION_AIRPORT,
    SECTION_CARRIER: SECTION_CARRIER,
    SECTION_CURRENCY: SECTION_CURRENCY,
    SECTION_ORDERS: SECTION_ORDERS,

    SECTION_TIPO_CONTENEDOR: SECTION_TIPO_CONTENEDOR,
    SECTION_PUERTO: SECTION_PUERTO,
    SECTION_BUQUE: SECTION_BUQUE,
    SECTION_COMPANIA_TRASNPORTE: SECTION_COMPANIA_TRASNPORTE,
    SECTION_TIPO_CAMION: SECTION_TIPO_CAMION,
    SECTION_LUGAR: SECTION_LUGAR,

    SECTION_USER: SECTION_USER,
    SECTION_ROLE:SECTION_ROLE,

    SECTION_ORDERS_MARITIMAS_COM: SECTION_ORDERS_MARITIMAS_COM,
    SECTION_ORDERS_MARITIMAS_OP: SECTION_ORDERS_MARITIMAS_OP,
    SECTION_ORDERS_TERRESTRES_COM: SECTION_ORDERS_TERRESTRES_COM,
    SECTION_ORDERS_TERRESTRES_OP: SECTION_ORDERS_TERRESTRES_OP,

    SECTION_ESTADO: SECTION_ESTADO,
    SECTION_NAVIERA: SECTION_NAVIERA,
    SECTION_TERMINAL_DEP_FISCAP: SECTION_TERMINAL_DEP_FISCAP,
    SECTION_AGENTE: SECTION_AGENTE,
    SECTION_CODIGO_PUERTO: SECTION_CODIGO_PUERTO,
};
