<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card >
        <v-form v-on:submit.prevent="createItem" ref="modal_form" id="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row mt-3" ref="section">
              <div class="col-md-4">
                <v-text-field data-vv-as="operacion" label="Operacion*" name="operacion" v-model="item.operacion" v-validate="'required'" :disabled="isViewModal() || item.id > 0" />
                <span class="red--text">{{ errors.first("operacion") }}</span>
              </div>
              <div class="col-md-4">
                <v-combobox
                  v-model="item.shipper"
                  data-vv-as="shipper"
                  name="shipper"
                  item-text="name"
                  item-value="id"
                  :items="shippers"
                  label="Shipper*"
                  v-validate="'required'"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("shipper") }}</span>
              </div>

              <div class="col-md-4">
                <v-combobox
                  v-model="item.consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="consignees"
                  label="Consignee*"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>
              </div>

              <div class="col-md-6">
                <v-text-field data-vv-as="house_bl" label="House BL" name="house_bl" v-model="item.house_bl" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("house_bl") }}</span>
              </div>

              <div class="col-md-6">
                <v-text-field data-vv-as="master_bl" label="Master BL" name="master_bl" v-model="item.master_bl" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("master_bl") }}</span>
              </div>

              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Recoleccion"
                  :date="item.fecha_recoleccion"
                  :isRequired="false"
                  name="fecha_recoleccion"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_recoleccion = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Recoleccion (Confirmada)"
                  :date="item.fecha_recoleccion_confirmada"
                  :isRequired="false"
                  name="fecha_recoleccion_confirmada"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_recoleccion_confirmada = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Salida (ETD)"
                  :date="item.fecha_salida_etd"
                  :isRequired="false"
                  name="fecha_salida_etd"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_salida_etd = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Salida (ATD)"
                  :date="item.fecha_salida_atd"
                  :isRequired="false"
                  name="fecha_salida_atd"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_salida_atd = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Llegada (ETA)"
                  :date="item.fecha_llegada_eta"
                  :isRequired="false"
                  name="fecha_llegada_eta"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_llegada_eta = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Llegada (ATA)"
                  :date="item.fecha_llegada_ata"
                  :isRequired="false"
                  name="fecha_llegada_ata"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_llegada_ata = date;
                    }
                  "
                />
              </div>

              <div class="col-md-12">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.ultimo_estado"
                  data-vv-as="ultimo_estado"
                  name="ultimo_estado"
                  item-text="name"
                  item-value="id"
                  :items="estados"
                  label="Estado"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("ultimo_estado") }}</span>
              </div>
              <div class="col-md-12">
                <!-- READ ONLY -->
                <v-textarea outlined data-vv-as="comentarios" label="Comentarios*" name="comentarios" v-model="item.comentarios" :disabled="true"></v-textarea>
                <span class="red--text">{{ errors.first("comentarios") }}</span>
              </div>

              <div class="col-md-3">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.puerto_salida"
                  data-vv-as="puerto_salida"
                  name="puerto_salida"
                  item-text="name"
                  item-value="id"
                  :items="puertos"
                  label="Puerto de salida"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("puerto_salida") }}</span>
              </div>
              <div class="col-md-3">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.ultimo_buque"
                  data-vv-as="ultimo_buque"
                  name="ultimo_buque"
                  item-text="name"
                  item-value="id"
                  :items="buques"
                  label="Buque"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("ultimo_buque") }}</span>
              </div>

              <div class="col-md-3">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.puerto_llegada"
                  data-vv-as="puerto_llegada"
                  name="puerto_llegada"
                  item-text="name"
                  item-value="id"
                  :items="puertos"
                  label="Puerto de llegada"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("puerto_llegada") }}</span>
              </div>

              <div class="col-md-3">
                <v-text-field data-vv-as="referencia_cliente" label="Referencia cliente*" name="referencia_cliente" v-model="item.referencia_cliente" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("referencia_cliente") }}</span>
              </div>

              <div class="col-md-12">
                <v-checkbox label="LCL" :disabled="isViewModal()" v-model="item.lcl"></v-checkbox>
              </div>

              <hr class="mt-4" />
              <div class="col-md-4" v-if="item.lcl">
                <v-text-field data-vv-as="bultos" label="Bultos" name="bultos" v-model="item.bultos" v-validate="'integer|min:0'" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("bultos") }}</span>
              </div>

              <div class="col-md-4" v-if="item.lcl">
                <v-text-field data-vv-as="kilos" label="Kilos" name="kilos" v-model="item.kilos" v-validate="'integer|min:0'" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("kilos") }}</span>
              </div>

              <div class="col-md-4" v-if="item.lcl">
                <v-text-field data-vv-as="m3" label="M3" name="m3" v-model="item.m3" v-validate="'integer|min:0'" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("m3") }}</span>
              </div>

              <div v-if="!item.lcl" class="col-md-12">                

                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <p class="mb-0 title-acordion">
                        <b>Contenedores</b>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field data-vv-as="cantidad de contenedores" label="Cantidad de Conenedores" name="cantidad_conenedores" v-model="item.cantidad_conenedores" v-validate="'integer|min:0'" :disabled="isViewModal()" />
                      <span class="red--text">{{ errors.first("cantidad_conenedores") }}</span>

                      <InputTable
                        :isViewModal="isViewModal()"
                        :validator="$validator"
                        :headers="[{ text: 'ID Contenedor' }, { text: 'Tipo contenedor' }, { text: 'Nº Contenedor' }, { text: 'KGs' }, { text: 'M3' }, { text: 'Acciones' }]"
                        :items="item.contenedores"
                        :input_fields="[
                          { width: '20%', label: 'ID Contenedor', name: 'id_contenedor', type: 'text', required: false },
                          { label: 'Tipo contenedor', name: 'tipo_contenedor', type: 'select', required: false, list: tipos_contenedores, list_id: 'id', list_name: 'name' },
                          { label: 'Nº de contenedor*', name: 'numero', type: 'text', required: false },
                          { label: 'KGs', name: 'kgs', type: 'numeric', required: false, validate: 'decimal:2|min:0' },
                          { label: 'M3', name: 'm3', type: 'numeric', required: false, validate: 'decimal:2|min:0' },
                        ]"
                        :item_template="{
                          id: 0,
                          id_contenedor: '',
                          numero: '',
                          tipo_contenedor: null,
                          kgs: '',
                          33: '',
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <p class="mb-0 title-acordion">
                        <b>Packing List</b>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="isViewModal()"
                        table_title="Packing List"
                        :validator="$validator"
                        :headers="[{ text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.packing_lists"
                        :input_fields="[
                          {
                            label: 'Seleccionar factura',
                            name: 'packing_list_attached',
                            name_attached: 'packing_list_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          packing_list_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <p class="mb-0 title-acordion">
                        <b> Facturas</b>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="isViewModal()"
                        table_title="Facturas"
                        :validator="$validator"
                        :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.facturas"
                        :input_fields="[
                          { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false },
                          {
                            label: 'Seleccionar factura',
                            name: 'factura_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          nro_factura: '',
                          factura_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <p class="mb-0 title-acordion">
                        <b>Facturas de Agente</b>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>                      
                      <InputTable
                        :isViewModal="isViewModal()"
                        table_title="Facturas de Agente"
                        :validator="$validator"
                        :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.facturas_agentes"
                        :input_fields="[
                          { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false },
                          {
                            label: 'Seleccionar factura',
                            name: 'factura_agente_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          nro_factura: '',
                          factura_agente_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <!--<v-btn color="warning" text @click="nextModal(item)" v-if="!isViewModal() && this.item.id == 0">Siguiente</v-btn>-->
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import ModalMixin from "@/mixins/ModalMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import InputTable from "./InputTable.vue";

export default {
  mixins: [ModalMixin, RolMixin],
  components: { DatePickerEsp, InputTable },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    print: Function,
  },
  data() {
    return {
      menu: false,
      show: false,
      shippers: [],
      consignees: [],
      estados: [],
      puertos: [],
      buques: [],
      tipos_contenedores: [],
    };
  },
  watch: {
    item: {
      handler(val) {
        this.$emit("updateItem", this.item);
      },
      deep: true,
    },

    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    setDateValue(value, name) {
      this.item[name] = value;
    },
    isViewModal() {
      return this.formBtn == "";
    },
    loadComboData() {
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "estados").then((response) => {
        this.estados = response.result;
      });
      generalService.filter({}, "puertos").then((response) => {
        this.puertos = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "buques").then((response) => {
        this.buques = response.result;
      });
      generalService.filter({}, "tipos_contenedores").then((response) => {
        this.tipos_contenedores = response.result;
      });
    },
    createItem(e, nextFlag = false) {
      return this.$validator.validate().then((valid) => {
        if (valid) {
          if (e != null) e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          this.setLoading(true);

          let data = new FormData();
          data.append("id", this.item.id);
          data.append("operacion", this.item.operacion);
          data.append("shipper_id", this.item.shipper.id);
          data.append("consignee_id", this.item.consignee.id);
          //READ ONLY
          /*
          if (this.item.ultimo_buque != null)
            data.append("ultimo_buque_id", this.item.ultimo_buque.id);
          if (this.item.ultimo_estado != null)
            data.append("ultimo_estado_id", this.item.ultimo_estado.id);
          if (this.item.puerto_salida != null)
            data.append("puerto_salida_id", this.item.puerto_salida.id);
          if (this.item.puerto_llegada != null)
            data.append("puerto_llegada_id", this.item.puerto_llegada.id);
          */          

          if (this.item.referencia_cliente != null) data.append("referencia_cliente", this.item.referencia_cliente);

          if (this.item.house_bl != null) data.append("house_bl", this.item.house_bl);
          if (this.item.master_bl != null) data.append("master_bl", this.item.master_bl);
          if (this.item.fecha_recoleccion != null) data.append("fecha_recoleccion", this.item.fecha_recoleccion);
          if (this.item.fecha_recoleccion_confirmada != null) data.append("fecha_recoleccion_confirmada", this.item.fecha_recoleccion_confirmada);
          if (this.item.fecha_salida_etd != null) data.append("fecha_salida_etd", this.item.fecha_salida_etd);
          if (this.item.fecha_salida_atd != null)
            data.append("fecha_salida_atd", this.item.fecha_salida_atd);
          //READ ONLY
          /*  
          if (this.item.fecha_llegada_eta != null)
            data.append("fecha_llegada_eta", this.item.fecha_llegada_eta);
          if (this.item.fecha_llegada_ata != null)
            data.append("fecha_llegada_ata", this.item.fecha_llegada_ata);
          if (this.item.comentarios != null)
            data.append("comentarios", this.item.comentarios);
          */

          data.append("lcl", this.item.lcl ? 1 : 0);
          
          if(this.item.lcl && this.item.bultos != null)
            data.append("bultos", this.item.lcl && this.item.bultos != null? this.item.bultos : null);
          
          if(this.item.lcl && this.item.kgs != null)
            data.append("kgs", this.item.lcl && this.item.kgs != null? this.item.kgs : null);

          if(this.item.lcl && this.item.m3 != null)
            data.append("m3", this.item.lcl && this.item.m3 != null? this.item.m3 : null);          

          if (this.item.cantidad_conenedores != null) data.append("cantidad_conenedores", this.item.cantidad_conenedores);
          data.append("cantidad_conenedores_arr", this.item.contenedores.length);          
          if (this.item.contenedores.length > 0) {
            for (var i = 0; i < this.item.contenedores.length; i++) {
              data.append("contenedores_arr[]", JSON.stringify(this.item.contenedores[i]));
            }
          }

          //no se pueden adjuntar archivos en un array anidado
          data.append("cantidad_facturas", this.item.facturas.length);
          if (this.item.facturas.length > 0) {
            for (var k = 0; k < this.item.facturas.length; k++) {
              data.append("facturas_" + k + "_id", this.item.facturas[k].id);
              data.append("facturas_" + k + "_nro_factura", this.item.facturas[k].nro_factura);
              data.append("facturas_" + k + "_attached", this.item.facturas[k].factura_attached);
            }
          }

          //no se pueden adjuntar archivos en un array anidado
          //READ ONLY          
          data.append("cantidad_facturas_agente", this.item.facturas_agentes.length);
          if (this.item.facturas.length > 0) {
            for (var j = 0; j < this.item.facturas_agentes.length; j++) {
              data.append("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
              data.append("facturas_agentes_" + j + "_nro_factura", this.item.facturas_agentes[j].nro_factura);
              data.append("facturas_agentes_" + j + "_attached", this.item.facturas_agentes[j].factura_agente_attached);
              console.log("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id)
            }
          }          

          //no se pueden adjuntar archivos en un array anidado
          data.append("cantidad_packing_list", this.item.packing_lists.length);
          if (this.item.packing_lists.length > 0) {
            for (var v = 0; v < this.item.packing_lists.length; v++) {
              data.append("packing_list_" + v + "_id", this.item.packing_lists[v].id);
              data.append("packing_list_" + v + "_attached", this.item.packing_lists[v].packing_list_attached);
            }
          }

          if (this.item.id > 0) {
            return this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => this.setLoading(false));
          } else {
            return this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false, nextFlag);
                  this.$emit("loadList");
                  if (nextFlag) this.$validator.reset();
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
                return response;
              })
              .finally(() => this.setLoading(false));
          }
        }else{               
          var elmnt = document.getElementById("modal_form");
          elmnt.scrollIntoView(true); // for scrolling top
        }
      });
    },

    printModal() {
      this.createItem(null).then((response) => {
        this.$emit("print", response.result, "pdf");
      });
    },

    nextModal() {
      this.createItem(null, true);
    },
  },
};
</script>
